import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Decryptedid } from "../utils/BcruptEncyptid";
import { useDispatch, useSelector } from "react-redux";
import { usePDF, Resolution, Margin } from "react-to-pdf";
import html2canvas from "html2canvas";
import {
  DeleteimagesandNotesAction,
  adddocumentAction,
  addimagesandNotesAction,
  consultationformdetails,
  fetchCompletedConsultationById,
} from "../Redux/Actions/user/salon";
import { useFormik } from "formik";
import Signaturecompo from "../components/Signaturecompo";
import { useReactToPrint } from "react-to-print";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import moment from "moment";
const ViewConsultationForm = () => {
  const { id } = useParams();
  const { toPDF, targetRef } = usePDF({ filename: Date.now() + ".pdf" });
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [keynotessection, setKeynotes] = useState("");
  const [imagess, setImagess] = useState([]);
  const [imagesshow, Setimagesshow] = useState([]);
  const [notes, setNotes] = useState("");
  const [entry_type, setentry_type] = useState("");
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const [showEdit, setshowEdit] = useState(false);
  const handleCloseEdit = () => setshowEdit(false);
  const handleShowEdit = () => setshowEdit(true);
  const [notesedit, setnotesedit] = useState("");

  const [documentid, setdocumentid] = useState("");
  const [entry_typeedit, setentry_typeedit] = useState("");

  var idvalue = Decryptedid(atob(id));
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const completed_consultation_by_id = useSelector(
    (state) => state.myaccount.completed_consultation_by_id
  );

  const consultationquestion = completed_consultation_by_id?.consultationId;

  console.log(completed_consultation_by_id, "completed_consultation_by_id");

  useEffect(() => {
    dispatch(fetchCompletedConsultationById(idvalue));
  }, [idvalue]);

  useEffect(() => {
    setNotes(completed_consultation_by_id?.notes);
    setentry_type(completed_consultation_by_id?.entry_type);

    if (completed_consultation_by_id?.images?.length > 0) {
      const imageUrls = completed_consultation_by_id?.images?.map(
        (file) =>
          process.env.REACT_APP_HOST_NAME +
          "completedconsultationimages/" +
          file
      );
      Setimagesshow(imageUrls);
    }
  }, [completed_consultation_by_id]);

  const initialValues = {
    // Set other static initial values here
  };

  // Dynamically add fields to initialValues
  for (let i = 0; i < consultationquestion?.question?.length; i++) {
    console.log(
      consultationquestion?.question,
      "consultationquestion?.question"
    );
    if (consultationquestion?.question[i]?.optiontype == 1) {
      //  0-radio 1-multile 2-input
      initialValues[`checkBoxQues${i + 1}`] = [];
    } else if (consultationquestion?.question[i]?.optiontype == 0) {
      initialValues[`radioQues${i + 1}`] = "";
    } else if (consultationquestion?.question[i]?.optiontype == 6) {
      initialValues[`signature${i + 1}`] = "";
    } else if (consultationquestion?.question[i]?.optiontype == 4) {
      initialValues[`clientImage${i + 1}`] = { img1: "", img2: "" };
    } else {
      initialValues[`dynamicQues${i + 1}`] = "";
    }
  }

  const formik = useFormik({
    initialValues: completed_consultation_by_id?.answers || initialValues,
    enableReinitialize: true,
  });

  const toggleCheckbox = (options, option) => {
    if (options?.some((data) => data.answer == option.answer)) {
      return options?.filter((item) => item?.answer !== option?.answer);
    } else {
      return [...options, option];
    }
  };

  const addimagesandNotes = () => {
    if (entry_type != "" && entry_type != undefined) {
      let formdata = new FormData();
      formdata.append("id", idvalue);
      formdata.append("documentid", documentid);

      for (let i = 0; i < imagess.length; i++) {
        formdata.append("images", imagess[i]);
      }
      formdata.append("entry_type", entry_type);
      formdata.append("notes", notes);

      dispatch(addimagesandNotesAction(formdata)).then(function () {
        dispatch(fetchCompletedConsultationById(idvalue));
        setentry_type("");
        setNotes("");
        setImages([]);
      });
    } else {
      toast.error("Entery type is required");
    }
  };
  const addimagesandNotesupdate = () => {
    if (entry_typeedit != "" && entry_typeedit != undefined) {
      let formdata = new FormData();
      formdata.append("id", idvalue);
      formdata.append("documentid", documentid);

      for (let i = 0; i < imagess.length; i++) {
        formdata.append("images", imagess[i]);
      }
      formdata.append("entry_type", entry_typeedit);
      formdata.append("notes", notesedit);

      dispatch(addimagesandNotesAction(formdata)).then(function () {
        dispatch(fetchCompletedConsultationById(idvalue));
        setshowEdit(false);
      });
    } else {
      toast.error("Entery type is required");
    }
  };

  // const printDocument = () => {
  //   const input = document.getElementById("divToPrint");

  //   // Ensure all images are fully loaded
  //   const images = input.querySelectorAll("img");
  //   const promises = Array.from(images).map((img) => {
  //     if (!img.complete) {
  //       return new Promise((resolve) => {
  //         img.onload = img.onerror = resolve;
  //       });
  //     }
  //     return Promise.resolve();
  //   });

  //   Promise.all(promises).then(() => {
  //     let x = input.querySelector("#multiple");

  //     if (x) {
  //       x.remove();
  //     }

  //     html2canvas(input, {
  //       useCORS: true, // Ensure CORS is handled
  //       allowTaint: true, // Allow cross-origin images to be used
  //     }).then((canvas) => {
  //       // A4 dimensions in points
  //       const a4Width = 595.28;
  //       const a4Height = 841.89;

  //       // Calculate scale to fit content within A4 dimensions
  //       const scaleX = a4Width / canvas.width;
  //       const scaleY = a4Height / canvas.height;
  //       const scale = Math.min(scaleX, scaleY);

  //       // Scale canvas dimensions to fit A4 dimensions
  //       const pdfWidth = canvas.width * scale;
  //       const pdfHeight = canvas.height * scale;

  //       const pdf = new jsPDF();
  //       console.log(pdfHeight);

  //       pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 10, 10, 180, 250);
  //       pdf.save(Date.now() + ".pdf");

  //       // Optionally reset any changes made to the DOM
  //       // setKeynotes('')
  //     });
  //   });
  // };

  
  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    
    const elementToRemove = input.querySelector("#multiple");
    
    const images = input.querySelectorAll("img");
    const promises = Array.from(images).map(img => {
        if (!img.complete) {
            return new Promise(resolve => {
                img.onload = img.onerror = resolve;
            });
        }
        return Promise.resolve();
    });
    
    Promise.all(promises).then(() => {
        if (elementToRemove) {
            elementToRemove.style.display = "none";
        }
        
        html2canvas(input, {
            useCORS: true,
            allowTaint: true,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            scale: 2 // Increase scale for better quality
        }).then((canvas) => {
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;
            
            const a4Width = 210; 
            const a4Height = 297; 
            
            const margin = 10; 
            const contentMaxWidth = a4Width - margin * 2 ;
            
            const pxToMm = 0.264583;
            const contentWidthMm = contentWidth * pxToMm;
            const scaleToFitWidth = contentMaxWidth / contentWidthMm;
            const requiredHeightMm = contentHeight * pxToMm * scaleToFitWidth;

            if(requiredHeightMm < 220) {
            
            const pdf = new jsPDF("p", "mm", 'a4');
            
            const imageData = canvas.toDataURL("image/jpeg", 1.0);
            
            const xOffset = margin;
            const yOffset = margin;
            
            pdf.addImage(imageData, "PNG", xOffset, yOffset, contentWidthMm * scaleToFitWidth, requiredHeightMm - margin * 2);
            
            if (elementToRemove) {
                elementToRemove.style.display = "block";
            }
            pdf.save(`document_${Date.now()}.pdf`);

             } else {
              const pdf = new jsPDF("p", "mm", [a4Width, requiredHeightMm], true);
            
              const imageData = canvas.toDataURL("image/jpeg", 1.0);
              
              const xOffset = margin;
              const yOffset = margin;
              
              pdf.addImage(imageData, "PNG", xOffset, yOffset, contentWidthMm * scaleToFitWidth, requiredHeightMm - margin * 2);
              
              if (elementToRemove) {
                  elementToRemove.style.display = "block";
              }
              pdf.save(`document_${Date.now()}.pdf`);

             }
        });
    });
};





  return (
    <div className="consulting_form">
      {/* Header Start */}
      <Navbar expand="lg" className="bg-body-tertiary header-blck">
        <Container>
          <Navbar.Brand href="/dashboard">
            <img
              className="main-logo"
              src={require("../assets/img/newconsultlogo.webp")}
              alt="logo"
            />
          </Navbar.Brand>
          <NavLink className="exit_btn" to={"/consultation"}>
            Exit
          </NavLink>
        </Container>
      </Navbar>
      {/* Header End */}

      {/* search header */}
      <section className="search-header print-form">
        <div className="container">
        <style>
        {`
          @media print {
            @page {
              margin: 0 10px;
            }
          }
        `}
      </style>
          <div ref={componentRef}>
            <Row>
              <div className="col-lg-12">
                <div className="prev_main">
                  <img
                    src={
                      require("../../src/assets/img/right-arrow.svg").default
                    }
                    alt="arrow"
                    className="arrow-next-green"
                  />
                  <NavLink to={"/consultation"} class="prev_result">
                    Back to All Forms
                  </NavLink>
                </div>
              </div>
              <div className="hide">
                <img src={require("../assets/img/logo.png")} alt="logo" />
              </div>
              <Col xs={12} md={12}>
                <div className="button-link ms-2 d-flex align-items-end justify-content-end pdf-download-btns">
                  <Nav.Link
                    className="white-box-link"
                    onClick={() => printDocument()}
                  >
                    Download
                  </Nav.Link>

                  <Nav.Link
                    onClick={handlePrint}
                    href="#"
                    className="white-box-link ms-2"
                  >
                    Print
                  </Nav.Link>
                </div>
              </Col>
            </Row>
            <div ref={targetRef} id="divToPrint">
              <Row>
                <Col xs={12} md={9}>
                  <div>
                    <h2>{completed_consultation_by_id?.salonId?.salonname}</h2>
                    <h2>{consultationquestion?.form_title}</h2>
                    <p className="mt-3 mb-0">
                      {
                        completed_consultation_by_id?.consultationId
                          ?.form_description
                      }
                    </p>
                    <p className="mt-2">
                      Client:{" "}
                      {completed_consultation_by_id?.customerId?.first_name +
                        " " +
                        completed_consultation_by_id?.customerId?.last_name}
                    </p>
                  </div>
                </Col>
              </Row>

              <form
                onSubmit={formik.handleSubmit}
                className="symptoms_form consult-form mt-4"
              >
                <div className="row">
                  {consultationquestion?.question?.map(function (
                    question_object,
                    i
                  ) {
                    return (
                      <>
                        {
                          <div className="col-lg-12 printable-item" key={i}>
                            <div className="form_box">
                              {
                                <label>
                                  <span>{i + 1}.</span>{" "}
                                  {question_object.optiontype == 5
                                    ? ""
                                    : question_object.question}
                                </label>
                              }
                              {(() => {
                                if (question_object.optiontype == 4) {
                                  return (
                                    <div className="create_new_form">
                                      <div className="row">
                                        {
                                          // formik.values[`clientImage${i + 1}`]
                                          //     ?.img1 &&
                                          <div className="col-6">
                                            <div className="upload_img_div">
                                              {formik.values[
                                                `clientImage${i + 1}`
                                              ]?.img1 ? (
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_HOST_NAME +
                                                    "Adminquestionimage/" +
                                                    formik.values[
                                                      `clientImage${i + 1}`
                                                    ]?.img1
                                                  }
                                                />
                                              ) : (
                                                <p>Upload image</p>
                                              )}
                                            </div>
                                          </div>
                                        }
                                        {
                                          // formik.values[`clientImage${i + 1}`]
                                          //     ?.img2 &&
                                          <div className="col-6">
                                            <div className="upload_img_div">
                                              {formik.values[
                                                `clientImage${i + 1}`
                                              ]?.img2 ? (
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_HOST_NAME +
                                                    "Adminquestionimage/" +
                                                    formik.values[
                                                      `clientImage${i + 1}`
                                                    ]?.img2
                                                  }
                                                />
                                              ) : (
                                                <p>Upload image</p>
                                              )}
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  );
                                } else if (question_object.optiontype == 5) {
                                  return (
                                    <div className="declaration">
                                      <label>Declaration</label>
                                      <p> {question_object.question}</p>
                                      <div className="d-flex justify-content-end">
                                        <label>
                                          {question_object?.confirmation ||
                                            "I Confirm"}
                                        </label>
                                        <input
                                          type="checkbox"
                                          name={`dynamicQues${i + 1}`}
                                          value={
                                            formik.values[`dynamicQues${i + 1}`]
                                          }
                                          checked={
                                            formik.values[`dynamicQues${i + 1}`]
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                } else if (question_object.optiontype == 6) {
                                  return (
                                    <>
                                      <Signaturecompo
                                        formik={formik}
                                        index={i}
                                      />
                                    </>
                                  );
                                }
                              })()}

                              {question_object.optiontype == 2 ? (
                                <div className="consult_form">
                                  <textarea
                                    class="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="2"
                                    placeholder="Enter your answer here"
                                    name={`dynamicQues${i + 1}`}
                                    value={formik.values[`dynamicQues${i + 1}`]}
                                  ></textarea>
                                </div>
                              ) : (
                                (question_object.optiontype == 0 ||
                                  question_object.optiontype == 1 ||
                                  question_object.optiontype == 3) && (
                                  <div
                                    className={
                                      question_object.optiontype == 3
                                        ? " options-form-images"
                                        : " options_form"
                                    }
                                  >
                                    {question_object?.options?.map(function (
                                      option,
                                      optionindex
                                    ) {
                                      return (
                                        <>
                                          <div
                                            className="form-check"
                                            key={optionindex}
                                          >
                                            {(() => {
                                              if (
                                                question_object.optiontype == 3
                                              ) {
                                                return (
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_HOST_NAME +
                                                      "/Adminquestionimage" +
                                                      option[
                                                        `imagename${
                                                          optionindex + 1
                                                        }`
                                                      ]
                                                    }
                                                    alt={option.imageurl1}
                                                    className="option_image"
                                                  />
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <input
                                                      className={
                                                        question_object.optiontype ==
                                                        0
                                                          ? "form-radio-input"
                                                          : "form-check-input"
                                                      }
                                                      type={
                                                        question_object.optiontype ==
                                                        0
                                                          ? "radio"
                                                          : "checkbox"
                                                      }
                                                      name={
                                                        question_object.optiontype ==
                                                        0
                                                          ? `radioQues${i + 1}`
                                                          : `checkBoxQues${
                                                              i + 1
                                                            }`
                                                      }
                                                      value={`option${
                                                        optionindex + 1
                                                      }`}
                                                      checked={
                                                        question_object.optiontype ==
                                                        0
                                                          ? formik.values[
                                                              `radioQues${
                                                                i + 1
                                                              }`
                                                            ]?.answer ==
                                                            `option${
                                                              optionindex + 1
                                                            }`
                                                          : formik.values[
                                                              `checkBoxQues${
                                                                i + 1
                                                              }`
                                                            ]?.some(
                                                              (data) =>
                                                                data.answer ==
                                                                `option${
                                                                  optionindex +
                                                                  1
                                                                }`
                                                            )
                                                      }
                                                    />

                                                    <label
                                                      className="form-check-label"
                                                      for="flexRadioDefault1"
                                                    >
                                                      {option?.optiontitle}
                                                    </label>
                                                  </>
                                                );
                                              }
                                            })()}
                                          </div>
                                          <div className="error_msg consult-view">
                                            {question_object.optiontype == 0
                                              ? formik.values[
                                                  `radioQues${i + 1}`
                                                ]?.answer ==
                                                  `option${optionindex + 1}` &&
                                                option?.custommessage
                                              : formik.values[
                                                  `checkBoxQues${i + 1}`
                                                ]?.includes(
                                                  `option${optionindex + 1}`
                                                ) && option?.custommessage}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        }
                      </>
                    );
                  })}
                </div>

                <h2
                  className="mb-2"
                  style={{ fontSize: "17px", textAlign: "center" }}
                >
                  After Treatment Notes
                </h2>
                {completed_consultation_by_id?.documents?.map(function (
                  object
                ) {
                  return (
                    <div className="form_card print-hide">
                      <div className="past-consult-add">
                        <div className="notes-buttons">
                          <button
                            onClick={() => {
                              setentry_typeedit(object?.entry_type);
                              setnotesedit(object?.notes);
                              setdocumentid(object?._id);

                              setImages();

                              const imageUrls = object?.images?.map(
                                (file) =>
                                  process.env.REACT_APP_HOST_NAME +
                                  "completedconsultationimages/" +
                                  file
                              );

                              setImages(imageUrls); //

                              handleShowEdit();
                            }}
                          >
                            <img
                              src={
                                require("../assets/img/pencil-edit.svg").default
                              }
                            />
                          </button>
                          <button
                            onClick={() => {
                              setdocumentid(object?._id);
                              handleShow();
                            }}
                          >
                            {" "}
                            <img
                              src={require("../assets/img/closeconsult.png")}
                            />
                          </button>
                        </div>
                        <h3>
                          {moment(object?.createdAt).format(
                            "DD/MM/YYYY HH:MM A"
                          )}
                        </h3>
                        <h2>{object?.entry_type}</h2>
                        <p>
                          {object?.notes == "undefined" ? "" : object?.notes}
                        </p>
                        <div className="view-images-set">
                          {object?.images?.map(function (file) {
                            return (
                              <img
                                src={
                                  process.env.REACT_APP_HOST_NAME +
                                  "completedconsultationimages/" +
                                  file
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div id="multiple">
                  <div
                    className={
                      "form_card past-consult-section " + keynotessection
                    }
                  >
                    {" "}
                    <div className="row">
                      <div className="col-12  text-center justify-content-center">
                        <h2 className="mb-2">New Entry</h2>
                        {/* <p className="mt-0 text-center" style={{ color: "#000" }}>
                        Please include any after photos & detail any products
                        used, any adverse reactions
                      </p> */}
                      </div>
                    </div>
                    <div className="notes-add-field">
                      <input
                        type="text"
                        placeholder="Entry Type"
                        className="form-control"
                        value={entry_type}
                        onChange={(e) => {
                          setentry_type(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div className="notes-add-field">
                      <div className="text-field">
                        <textarea
                          rows={3}
                          placeholder="Add Notes"
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-left mt-0 mx-auto">
                      <div className="view-images-set">
                        {notesedit == "" &&
                          images?.map(function (file) {
                            return <img src={file} />;
                          })}
                      </div>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-center mt-0 mx-auto">
                      <div className="upload-consult-main">
                        <button className="upload-images-consult">
                          Upload Images
                        </button>
                        <input
                          type="file"
                          className="form-control upload-input"
                          multiple
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            setImagess(files);
                            const imageUrls = files.map((file) =>
                              URL.createObjectURL(file)
                            );
                            console.log(imageUrls);
                            setImages(imageUrls); // Assuming setImages expects an array of URLs
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-center mt-4 mx-auto">
                      <button
                        className="white-box-link nav-link"
                        onClick={addimagesandNotes}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* search list */}

      <Modal show={showEdit} onHide={handleCloseEdit} className="" size="lg">
        <Modal.Header closeButton style={{ border: "0" }}></Modal.Header>
        <Modal.Body>
          {" "}
          <div className="form_card mt-0 pt-0" style={{ border: "0" }}>
            {" "}
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <h2>Edit Entry</h2>
              </div>
            </div>
            <div className="notes-add-field">
              <input
                type="text"
                placeholder="Entry Type"
                className="form-control"
                value={entry_typeedit}
                onChange={(e) => {
                  setentry_typeedit(e.target.value);
                }}
              />{" "}
            </div>
            <div className="notes-add-field">
              <div className="text-field">
                <textarea
                  rows={3}
                  placeholder="Add Notes"
                  value={notesedit}
                  onChange={(e) => {
                    setnotesedit(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-12 d-flex justify-content-left mt-0 mx-auto">
              <div className="view-images-set">
                {images.map(function (file) {
                  return <img src={file} />;
                })}
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center mt-0 mx-auto">
              <div className="upload-consult-main">
                <button className="upload-images-consult">Upload Images</button>
                <input
                  type="file"
                  className="form-control upload-input"
                  multiple
                  onChange={(e) => {
                    const files = Array.from(e.target.files);
                    setImagess(files);
                    const imageUrls = files.map((file) =>
                      URL.createObjectURL(file)
                    );
                    console.log(imageUrls);
                    setImages(imageUrls); // Assuming setImages expects an array of URLs
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center mt-4 mx-auto">
              <button
                className="white-box-link nav-link"
                onClick={addimagesandNotesupdate}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} className="">
        <Modal.Header closeButton style={{ border: "0" }}></Modal.Header>
        <Modal.Body>
          <div className="permission-popup text-center">
            <p> Are You Sure to Delete This Note</p>
          </div>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-center"
          style={{ border: "0" }}
        >
          <div className="submit-btn schedule p-0">
            <button
              type="submit"
              class="btn mb-3"
              onClick={() => {
                dispatch(
                  DeleteimagesandNotesAction({
                    documentid: documentid,
                  })
                ).then(function () {
                  dispatch(fetchCompletedConsultationById(idvalue));
                  setshow(false);
                });
              }}
            >
              Delete
            </button>
          </div>
          <div className="cancel-btn" onClick={handleClose}>
            <button type="submit" class="btn mb-3">
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewConsultationForm;
