import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { informationlistAction } from '../Redux/Actions/user/salon';
import MyAcoountLayout from '../components/Layout/MyAcoountLayout';
import { Accordion } from 'react-bootstrap';

export default function Faq() {
    const dispatch=useDispatch();
    useEffect(()=>{
    dispatch(informationlistAction())
    },[])
    const faqlist = useSelector(
      (state) => state.myaccount?.faqlist
    );

    

    
  return (
    <MyAcoountLayout DidYouKnow={true}>
   
     

    <div className="col-lg-10 mx-auto">
    <div className="customer-faqs">
      <Accordion defaultActiveKey="0">
      {
        faqlist?.map(function(object,i){
          return(
            <Accordion.Item eventKey={i}>
          <Accordion.Header>{object?.question}</Accordion.Header>
          <Accordion.Body>
          {  JSON.parse(object?.answer).blocks?.map(block => block.text)}
          </Accordion.Body>
        </Accordion.Item>
          )
        })
      }

    
  
      </Accordion>
    </div>
  </div>

  </MyAcoountLayout>
  )
}
